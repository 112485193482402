import React from 'react';
import styled from 'styled-components'
import {toggleLoadDataFromLocalstorage} from "../../state/loadDataFromLocalStorage";
import {useDispatch} from "react-redux";
import {toggleModalVisibility} from "../../state/showLoadDataModal";

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: fixed !important;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.7);
  z-index: 100000000000000000;
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: ${({theme}) => theme.colors.formBlue};
  border-radius: 25px;
  position: relative;
  max-width: 550px;
  margin: 0 auto;
  padding: 30px 0;
  @media(min-width: 768px) {
  	max-width: 700px;
  }
  @media(min-width: 1024px) {
    width: 800px;
    height: 370px;
    margin: 0 auto;
    max-width: unset;
  }
`

const Header = styled.div`
	font-weight: ${({theme}) => theme.fontWeight.bold};
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	position: relative;
	text-align: center;
	padding: 30px 10% 25px;
	width: 100%;
	font-size: 20px;
	@media(min-width: 768px){
		font-size: 32px;
		line-height: 38px;
		padding: 40px 10% 35px;
	}
`;

const Description = styled.div`
	font-size: 14px;
	line-height: 22px;
	font-weight: ${({theme}) => theme.fontWeight.light};
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	position: relative;
	text-align: center;
	padding: 10px 10% 20px;
	width: 100%;
	span {
	  font-weight: ${({theme}) => theme.fontWeight.semiBold};
	}
	@media(min-width: 768px){
		font-size: 22px;
		line-height: 38px;
		padding: 0 10% 20px;
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	div {
		&:nth-child(odd) {
			margin-right: 20px;
		}
	}	
`

export const SimpleButton = styled.div`
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	width: 100px;
	height: 40px;
	border-radius: 6px;
	border: 1px solid ${({theme}) => theme.colors.formFontDarkBlue};
	background-color: ${({theme}) => theme.colors.formWhite};
	position: relative;
	cursor: pointer;
	font-size: 16px;
	line-height: 20px;
	font-weight: ${({theme}) => theme.fontWeight.bold};
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	&:hover {
		border: 2px solid ${({ theme }) => theme.colors.formAlwisOrange};
		box-shadow: 0 3px 5px 0 rgba(0,0,0,0.09) !important;
		background-color: ${({theme}) => theme.colors.formAlwisOrangeLightOrange}
	}
	@media(min-width: 768px) {
        width: 170px;
	  	height: 50px;
	}
	@media(min-width: 1280px) {
		margin-bottom: 0;
	}
	&.active {
		border: 2px solid ${({ theme }) => theme.colors.formAlwisOrange};
		box-shadow: 0 3px 5px 0 rgba(0,0,0,0.09) !important;
		background-color: ${({theme}) => theme.colors.formAlwisOrangeLightOrange}
	}
`

const LoadDataModal = () => {
	const dispatch = useDispatch()

	return (
		<ModalWrapper>
			<InfoWrapper key="modal">
				<Header>Czy chcesz wrócić do poprzedniej kalkulacji, wczytanej z pamięci przeglądarki?</Header>
				{false && <Description>Miasto: <b>{localStorage.getItem('city')}</b>, <br/> ulica: <b>{localStorage.getItem('street')}</b>, <br/> numer:
					<b>{localStorage.getItem('buildingNumber')}</b></Description>}
				<ButtonsContainer>
					<SimpleButton id={1}
	                       key={1}
	                       htmlId={'load-data-from-localstorage-yes'}
	                       onClick={() => {
		                       dispatch(toggleLoadDataFromLocalstorage(true))
		                       dispatch(toggleModalVisibility(false))}}>tak</SimpleButton>
					<SimpleButton id={2}
					        key={2}
					        htmlId={'load-data-from-localstorage-no'}
					        onClick={() => {
						        dispatch(toggleLoadDataFromLocalstorage(false))
					            dispatch(toggleModalVisibility(false))
					            localStorage.removeItem('propertyForm')
					        }}>nie</SimpleButton>
				</ButtonsContainer>
			</InfoWrapper>
		</ModalWrapper>
	)
}

export default LoadDataModal