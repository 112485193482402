import { combineReducers } from "redux"
import { reducer as formReducer } from 'redux-form'
import form from "./form"
import timeLeft from "./timeLeft"
import setPagesAreFilled from "./setPagesAreFilled"
import showPopup from "./showPopup"
import cities from "./cities"
import showLoadDataModal from "./showLoadDataModal"
import loadDataFromLocalStorage from "./loadDataFromLocalStorage"
import showNotSellingModal from "./showNotSellingModal";
import sendDataToFortWebApi from "./sendDataToFortWebApi"
import getDataFromFortWebApi from "./getDataFromLead";
import lead from "./lead";

export default combineReducers({
	form: formReducer,
	timeLeft,
	setPagesAreFilled,
	showPopup,
	showLoadDataModal,
	showNotSellingModal,
	cities,
	loadDataFromLocalStorage,
	sendDataToFortWebApi,
	getDataFromFortWebApi,
	lead,
})
