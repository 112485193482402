const initialState = {
	isLoadDataModalShown: false,
}

const TOGGLE_MODAL_VISIBILITY = "TOGGLE_MODAL_VISIBILITY";

export const toggleModalVisibility = isLoadDataModalShown => ({
	type: TOGGLE_MODAL_VISIBILITY, isLoadDataModalShown,
})

export default (state = initialState, action) => {
	if (action.type === TOGGLE_MODAL_VISIBILITY) {
		return { ...state, isLoadDataModalShown: action.isLoadDataModalShown }
	} else {
		return state
	}
};
