const initialState = {
	isNotSellingModalShown: false,
}

const TOGGLE_NOT_SELLING_MODAL = "TOGGLE_NOT_SELLING_MODAL";

export const toggleNotSellingModalVisibility = isNotSellingModalShown => ({
	type: TOGGLE_NOT_SELLING_MODAL, isNotSellingModalShown,
})

export default (state = initialState, action) => {
	if (action.type === TOGGLE_NOT_SELLING_MODAL) {
		return { ...state, isNotSellingModalShown: action.isNotSellingModalShown }
	} else {
		return state
	}
};
