export const theme = {
	colors: {
		formBlack: "#000",
		formWhite: "#FFF",
		formBlue: "#BFDEFF",
		formMediumBlue: "#7CB9FA",
		formFontDarkBlue: "#436C98",
		formStepDoneBlue: "#96B9DE",
		formAlwisOrange: "#FC5757",
		formAlwisOrangeLightOrange: "#FAEFEF",
		formBackgroundColor: "#F5F7F9",
		// formOrange: "#FEA328",
		formLightGrey: "#959595",
		formGreySidebar: "#CCCCCC",
		formGreyNumber: "#4E4E65",
		formGreen: "#4DCBAB",
		selectorGreyBorder: "#ECEFF5",
		formTransparent: "transparent",
		formSubStepsGrey: "#32354A",
		formLightGreen: "#CAE6D8",
		formBorderGreen: "#72C79D",
		formDarkOrange: "#F87044",
		formDarkGreen: "#47665A",
		formStepDoneGreen: "rgba(74, 103, 92, 0.3)",
		formTileActiveOrange: "#F96F44",
		formTileActiveBackgroundOrange: "#FDF8F7",
		formInputGreyBackground: "#EEF1F2",
        formDatepickerOrange: "#F88D6E",
		formValidationRedBorder: "#F83B3B",
	},
	fontFamily: {
		mainFont: "'Source Sans Pro', sans-serif",
		numberFont: "'Open Sans', sans-serif",
	},
	fontWeight: {
		extraLight: 200,
		light: 300,
		regular: 400,
		semiBold: 600,
		bold: 700,
		extraBold: 800,
		black: 900,
	}
};
