import React from "react"
import styled from "styled-components"
import Logo from "../../images/SideStepsPreview/alwis-logo.png"
import Tick from "../../images/SideStepsPreview/tick-img.svg"
import TickMobile from "../../images/SideStepsPreview/tick-mobile.svg"
import Clouds from "../../images/SideStepsPreview/clouds.svg"
import { changeTimeLeft } from "../../state/timeLeft"
import {connect, useDispatch} from "react-redux";
import {Link} from "gatsby";
import AnimationFirstStep from "../../animations/jaka-nieruchomosc-chcesz-ubezpieczyc";
import AnimationSecondStep from "../../animations/szczegoly-nieruchomosci";
import AnimationThirdStep from "../../animations/historia-nieruchomosci";
import AnimationFourthStep from "../../animations/zakres-ubezpieczenia";
import AnimationFifthStep from "../../animations/wysokosc-ubezpieczenia";
import SingleAnimationComponent from "../menuAnimations/SingleAnimationComponent";

const ModuleWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100vw;
	height: 82px;
	background-color: ${({theme}) => theme.colors.formBlue};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 10px;
	@media(min-width: 375px) {
		padding: 0 20px;
	}
	@media(min-width: 768px) {
		height: 125px;
	}
	@media(min-width: 1024px) {
		width: 340px;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: 0 20px 15px;
		background-color: ${({theme}) => theme.colors.formWhite};
	}
	@media(min-width: 1920px) {
		width: 405px;
	}
`;

const LogoContainer = styled.a`
	display: none;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	width: 100%;
	height: 160px;
	@media(min-width: 1024px) {
		display: flex;
		height: 116px;
	}
	@media(min-width: 1280px) {
	  height: 116px;
	  @media(min-height: 900px) {
	  	height: 160px;
	  }
	}
`;

const LogoWrapper = styled.div`
	width: 72px;
	height: 60px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url( ${({bgImg}) => bgImg});
	@media(min-width: 1024px) {
		width: 160px;
		height: 57px;
		margin: 0 auto;
	}
	@media(min-width: 1280px) {
		@media(min-height: 900px) {
			width: 160px;
			height: 57px;
		}
	}
`;

const MobileStepPreviewWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	min-height: 82px;
	@media(min-width: 1024px) {
		display: none;
	}
`;

const MobileCurrentStepWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
`

const TabletLogoContainer = styled.div`
	display: none;
	flex-direction: row;
	justify-content: center;
	width: 160px;
	height: 57px;
	margin-bottom: 30px;
	position: absolute;
	left: calc(50% - 80px);
	top: 30px;
	@media(min-width: 768px) {
		display: flex;
	}
`;

const TabletLogoWrapper = styled.div`
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url( ${({bgImg}) => bgImg});
`;

const ProgressBarWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
`
const ProgressBar = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	height: 20px;
	@media(min-width: 375px) {
		height: 30px;
	}
`;

const ProgressLineWrapper = styled.div` 
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
`

const ProgressLine = styled.div`
	height: 1px;
	background-color: ${({theme}) => theme.colors.formMediumBlue};
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 8px;
	@media(min-width: 400px) {
		width: 12px;
	}
	&.wider {
		width: 14px; 
		@media(min-width: 400px) {
			width: 20px;
		}
	}
	&.done {
		background-color: ${({theme}) => theme.colors.formAlwisOrange};
	}
`

const StepsAndBottomImageWrapper = styled.div`
	background-color:  ${({theme}) => theme.colors.formBlue};
	border-radius: 30px;
	height: calc(100% - 116px);
	padding: 25px 15px 15px;
	display: none;
	flex-direction: column;
	justify-content: space-between;
	overflow-x: hidden;
	overflow-y: hidden;
	@media(min-width: 1024px) {
		display: flex;
	}
	@media(min-width: 1280px) {
	  height: calc(100% - 116px);
	  padding: 25px 35px 55px;
	  @media(min-height: 900px) {
	  	height: calc(100% - 160px);
	  }
	}
`;

const CloudsAndStepsWrapper = styled.div`
	display: none;
	flex-direction: column;
	justify-content: flex-start;
	@media(min-width: 1024px) {
		display: flex;
	}
`

const CloudsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	margin-left: 30px;
	margin-bottom: 30px;
	@media(min-width: 1280px) {
		margin-left: 10px;
	}
	@media(min-width: 1920px) {
		margin-bottom: 50px;
	}
`;

const CloudsImage = styled.img`
	height: 38px;
`

const StepsWrapper = styled.div`
	display: none;
	flex-direction: column;
	justify-content: space-between;
	margin: 0 auto;
	@media(min-width: 1024px) {
		display: flex;
	}
`;

const MainStep = styled(Link)`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 20px;
	pointer-events: ${({isLinkActive}) => isLinkActive ? "initial" : "none"};
	text-decoration: none;
	@media(min-width: 1280px) {
		@media(min-height: 900px) {
			margin-bottom: 40px;
		}
	}
`;

const MainStepNumber = styled.div`
	width: 20px;
	height: 20px;
	border: 1px solid ${({theme}) => theme.colors.formMediumBlue};
	border-radius: 50%;
	background-color: ${({theme}) => theme.colors.formTransparent};
	font-family:'Open Sans', sans-serif;
	font-size: 8px;
	line-height: 11px;
	font-weight: ${({theme}) => theme.fontWeight.extraBold};
	display: flex;
	justify-content: center;
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	pointer-events: ${({isLinkActive}) => isLinkActive ? "initial" : "none"};
	text-decoration: none;
	@media(min-width: 375px) {
		width: 30px;
		height: 30px;
		font-size: 14px;
		line-height: 19px;
	}
	&.filled {
		border: none;
		background-color: ${({theme}) => theme.colors.formAlwisOrange};
	}
	&.active {
		border: 1px solid ${({theme}) => theme.colors.formAlwisOrange};
		color: ${({theme}) => theme.colors.formAlwisOrange};
	}
	@media(min-width: 1024px) {
		padding-top: 2px;
		width: 30px;
		height: 30px;
		font-size: 13px;
		line-height: 18px;
		@media(min-height: 900px) {
			width: 35px;
			height: 35px;
			font-size: 14px;
			line-height: 19px;
		}
	}
`;

const MainStepNumberMobile = styled(Link)`
	width: 20px;
	height: 20px;
	border: 1px solid ${({theme}) => theme.colors.formMediumBlue};
	border-radius: 50%;
	background-color: ${({theme}) => theme.colors.formTransparent};
	font-family: ${({theme}) => theme.fontFamily.numberFont};
	font-size: 8px;
	line-height: 11px;
	font-weight: ${({theme}) => theme.fontWeight.extraBold};
	display: flex;
	justify-content: center;
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	pointer-events: ${({isLinkActive}) => isLinkActive ? "initial" : "none"};
	text-decoration: none;
	@media(min-width: 375px) {
		width: 30px;
		height: 30px;
		font-size: 14px;
		line-height: 19px;
	}
	&.filled {
		border: none;
		background-color: ${({theme}) => theme.colors.formAlwisOrange};
	}
	&.active {
		border: 1px solid ${({theme}) => theme.colors.formAlwisOrange};
		color: ${({theme}) => theme.colors.formAlwisOrange};
	}
	@media(min-width: 1024px) {
		font-size: 15px;
		line-height: 18px;
		padding-top: 6px;
		width: 36px;
		height: 36px;
	}
	@media(min-width: 1920px) {
		font-size: 16px;
		line-height: 22px;
		width: 40px;
		height: 40px;
		padding-top: 7px;
	}
`;

const NumberSpan = styled.span`
	display: block;
	margin-top: 3px;
	@media(min-width: 375px) {
		margin-top: 4px;
	}
	@media(min-width: 768px) {
		margin-top: 4px;
	}
	@media(min-width: 1024px) {
		margin-top: 3px;
		@media(min-height: 900px) {
			margin-top: 5px;
		}
	}
	&.filled {
		display: none;
	}
`;

const TickImg = styled.img`
	width: 8px;
	margin: 0 auto;
	@media(min-width: 375px) {
		width: 11px;
	}
	@media(min-width: 1024px) {
		width: 12px;
		padding-bottom: 2px;
		@media(min-height: 900px) {
			width: 15px;
		}
	}
`

const MainStepName = styled.p`
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	font-weight: ${({theme}) => theme.fontWeight.black};
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 18px;
	text-decoration: none;
	&.filled {
		border: none;
		color: ${({theme}) => theme.colors.formStepDoneBlue};
	}
	&.active {
		color: ${({theme}) => theme.colors.formAlwisOrange};
	}
	@media(min-width: 1024px) {
		font-size: 14px;
		line-height: 18px;
		margin-left: 15px;
		@media(min-height: 900px) {
			font-size: 16px;
			line-height: 20px;
		}
	}
`;

const BottomImagesWrapper = styled.div`
	display: none;
	flex-direction: row;
	justify-content: center;
	position: relative;
	width: 100%;
	margin: 0 auto;
	@media(min-width: 1024px) {
		display: flex;
	}
`

const SideStepsPreview = (props) => {
	const {path, firstStepFilled, secondStepFilled, thirdStepFilled, fourthStepFilled, fifthStepFilled } = props;
	let currentStep;
	let FirstStepActive;
	let SecondStepActive;
	let ThirdStepActive;
	let FourthStepActive;
	let FifthStepActive;
	const dispatch = useDispatch()

	switch (path) {
		case "/":
			FirstStepActive = true;
			SecondStepActive = false;
			ThirdStepActive = false;
			FourthStepActive = false;
			FifthStepActive = false;
			dispatch(changeTimeLeft("około 150 s"));
			currentStep = 1;
			break;
		case "/step-2/":
			FirstStepActive = false;
			SecondStepActive = true;
			ThirdStepActive = false;
			FourthStepActive = false;
			FifthStepActive = false;
			currentStep = 2;
			dispatch(changeTimeLeft("około 2 min"));
			break;
		case "/step-3/":
			FirstStepActive = false;
			SecondStepActive = false;
			ThirdStepActive = true;
			FourthStepActive = false;
			FifthStepActive = false;
			dispatch(changeTimeLeft("około 90 s"));
			currentStep = 3;
			break;
		case "/step-4/":
			FirstStepActive = false;
			SecondStepActive = false;
			ThirdStepActive = false;
			FourthStepActive = true;
			FifthStepActive = false;
			currentStep = 4;
			dispatch(changeTimeLeft("około 70 s"));
			break;
		case "/step-5/":
			FirstStepActive = false;
			SecondStepActive = false;
			ThirdStepActive = false;
			FourthStepActive = false;
			FifthStepActive = true;
			currentStep = 5;
			dispatch(changeTimeLeft("około 50 s"));
			break;
		default:
			FirstStepActive = true;
			SecondStepActive = false;
			ThirdStepActive = false;
			FourthStepActive = false;
			FifthStepActive = false;
			currentStep = 1;
			break;
	}
	const ThirdOrFourthOfFifthStepCondition = (ThirdStepActive || FourthStepActive || FifthStepActive);
	const FourthOrFifthStepCondition = (FourthStepActive || FifthStepActive);
	const currentStepName = FirstStepActive ? 'Dane nieruchomości' : SecondStepActive ? 'Szczegóły' : ThirdStepActive ? 'Historia ubezpieczenia' : FourthStepActive ?  'Zakres ubezpieczenia' : 'Wysokość ubezpieczenia';

	return (
		<ModuleWrapper>
			<LogoContainer target="_blank" href="https://alwis.pl">
				<LogoWrapper bgImg={Logo}/>
			</LogoContainer>
			<MobileStepPreviewWrapper>
				<MobileCurrentStepWrapper>
					<MainStepName>{currentStepName}</MainStepName>
				</MobileCurrentStepWrapper>
				<TabletLogoContainer>
					<TabletLogoWrapper bgImg={Logo}/>
				</TabletLogoContainer>
				<ProgressBarWrapper>
					<ProgressBar>
						<MainStepNumberMobile to={'/'} isLinkActive={firstStepFilled} className={FirstStepActive ? "active" : "filled"}>
							<NumberSpan className={!FirstStepActive ? "filled" :  ""}>1</NumberSpan>
							{ !FirstStepActive && <TickImg src={TickMobile}/>}
						</MainStepNumberMobile>
						<ProgressLineWrapper>
							<ProgressLine className={!FirstStepActive ? "done wider" :  "wider"}/>
						</ProgressLineWrapper>
						<MainStepNumberMobile to={'/step-2'} isLinkActive={secondStepFilled} className={SecondStepActive ? "active" : ThirdOrFourthOfFifthStepCondition ? "filled" : ""}>
							<NumberSpan className={ThirdOrFourthOfFifthStepCondition ? "filled" : ""}>2</NumberSpan>
							{ ThirdOrFourthOfFifthStepCondition && <TickImg src={TickMobile}/>}
						</MainStepNumberMobile>
						<ProgressLineWrapper>
							<ProgressLine className={ThirdOrFourthOfFifthStepCondition ? "done wider" :  "wider"}/>
						</ProgressLineWrapper>
						<MainStepNumberMobile to={'/step-3'} isLinkActive={thirdStepFilled} className={ThirdStepActive ? "active" : (FourthOrFifthStepCondition) ? "filled" : ""}>
							<NumberSpan className={(FourthOrFifthStepCondition) ? "filled" : ""}>3</NumberSpan>
							{FourthOrFifthStepCondition && <TickImg src={TickMobile}/>}</MainStepNumberMobile>
						<ProgressLineWrapper>
							<ProgressLine className={FourthOrFifthStepCondition ? "done wider" :  "wider"}/>
						</ProgressLineWrapper>
						<MainStepNumberMobile to={'/step-4'} isLinkActive={fourthStepFilled} className={FourthStepActive ? "active" : FifthStepActive ? "filled" : ""}>
							<NumberSpan className={FourthStepActive ? "active" : FifthStepActive ? "filled" : ""}>4</NumberSpan>
							{FifthStepActive && <TickImg src={TickMobile}/>}
						</MainStepNumberMobile>
						<ProgressLineWrapper>
							<ProgressLine className={FifthStepActive ? "done wider" :  "wider"}/>
						</ProgressLineWrapper>
						<MainStepNumberMobile to={'/step-5'} isLinkActive={fifthStepFilled} className={FifthStepActive ? "active" : ""}>
							<NumberSpan className={FifthStepActive ? "active" : ""}>5</NumberSpan>
						</MainStepNumberMobile>
					</ProgressBar>
				</ProgressBarWrapper>
			</MobileStepPreviewWrapper>
			<StepsAndBottomImageWrapper>
				<CloudsAndStepsWrapper>
				<CloudsWrapper>
					<CloudsImage src={Clouds}/>
				</CloudsWrapper>
				<StepsWrapper>
					<MainStep to={'/'} isLinkActive={firstStepFilled}>
						<MainStepNumber className={FirstStepActive ? "active" : "filled"}>
							<NumberSpan className={!FirstStepActive ? "filled" :  ""}>1</NumberSpan>
							{ !FirstStepActive && <TickImg src={Tick}/>}
						</MainStepNumber>
						<MainStepName className={FirstStepActive ? "active" : "filled"}>Dane nieruchomości</MainStepName>
					</MainStep>
					<MainStep to={'/step-2'} isLinkActive={secondStepFilled}>
						<MainStepNumber className={SecondStepActive ? "active" : ThirdOrFourthOfFifthStepCondition ? "filled" : ""}>
							<NumberSpan className={ThirdOrFourthOfFifthStepCondition ? "filled" : ""}>2</NumberSpan>
							{ ThirdOrFourthOfFifthStepCondition && <TickImg src={Tick}/>}
						</MainStepNumber>
						<MainStepName className={(SecondStepActive) ? "active" : (ThirdOrFourthOfFifthStepCondition) ? "filled" :  ""}>Szczegóły</MainStepName>
					</MainStep>
					<MainStep to={'/step-3'} isLinkActive={thirdStepFilled}>
						<MainStepNumber className={ThirdStepActive ? "active" : (FourthOrFifthStepCondition) ? "filled" : ""}>
							<NumberSpan className={(FourthOrFifthStepCondition) ? "filled" : ""}>3</NumberSpan>
							{FourthOrFifthStepCondition  && <TickImg src={Tick}/>}</MainStepNumber>
						<MainStepName className={(ThirdStepActive) ? "active" : (FourthOrFifthStepCondition) ? "filled" : ""}>Historia ubezpieczenia</MainStepName>
					</MainStep>
					<MainStep to={'/step-4'} isLinkActive={fourthStepFilled}>
						<MainStepNumber className={FourthStepActive ? "active" : FifthStepActive ? "filled" : ""}>
							<NumberSpan className={FourthStepActive ? "active" : FifthStepActive ? "filled" : ""}>4</NumberSpan>
							{FifthStepActive  && <TickImg src={Tick}/>}
						</MainStepNumber>
						<MainStepName className={(FourthStepActive) ? "active" : FifthStepActive ? "filled" : ""}>Zakres ubezpieczenia</MainStepName>
					</MainStep>
					<MainStep to={'/step-5'} isLinkActive={fifthStepFilled}>
						<MainStepNumber className={FifthStepActive ? "active" : ""}>
							<NumberSpan className={FifthStepActive ? "active" : ""}>5</NumberSpan>
						</MainStepNumber>
						<MainStepName className={(FifthStepActive) ? "active" : ""}>Wysokość ubezpieczenia</MainStepName>
					</MainStep>
				</StepsWrapper>
				</CloudsAndStepsWrapper>
				<BottomImagesWrapper>
					{FirstStepActive && <SingleAnimationComponent animationJson={AnimationFirstStep}/>}
					{SecondStepActive && <SingleAnimationComponent animationJson={AnimationSecondStep}/>}
					{ThirdStepActive && <SingleAnimationComponent animationJson={AnimationThirdStep}/>}
					{FourthStepActive && <SingleAnimationComponent animationJson={AnimationFourthStep}/>}
					{FifthStepActive && <SingleAnimationComponent animationJson={AnimationFifthStep}/>}
				</BottomImagesWrapper>
			</StepsAndBottomImageWrapper>
		</ModuleWrapper>
	)
};

export default connect(state => ({
	timeLeft: state.timeLeft.time,
	firstStepFilled: state.setPagesAreFilled.firstStepFilled,
	secondStepFilled: state.setPagesAreFilled.secondStepFilled,
	thirdStepFilled: state.setPagesAreFilled.thirdStepFilled,
	fourthStepFilled: state.setPagesAreFilled.fourthStepFilled,
	fifthStepFilled: state.setPagesAreFilled.fifthStepFilled,
}), null)(SideStepsPreview);
