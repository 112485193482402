import {getDataFromLead} from '../services/property-service'
const initialState = {
	loading: false,
	error: false,
	fetched: false,
	data: []
}

const GET_DATA_FROM_FORT_WEB_API = "GET_DATA_FROM_FORT_WEB_API"

export const getDataFromFortWebApiAction = (data) => ({
	type: GET_DATA_FROM_FORT_WEB_API,
	payload: getDataFromLead(data)
})

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_DATA_FROM_FORT_WEB_API + "_PENDING":
			return {...initialState, loading: true}
		case GET_DATA_FROM_FORT_WEB_API + "_REJECTED":
			return {...initialState, error: true}
		case GET_DATA_FROM_FORT_WEB_API + "FULFILLED":
			return {...initialState, fetched: true, data: action.payload.data}
		default:
			return state
	}
}