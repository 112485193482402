const initialState = {
	firstStepFilled: false,
	secondStepFilled: false,
	thirdStepFilled: false,
	fourthStepFilled: false,
	fifthStepFilled: false,
}

const SET_FIRST_STEP_FILLED = "SET_FIRST_STEP_FILLED";
const SET_SECOND_STEP_FILLED = "SET_SECOND_STEP_FILLED";
const SET_THIRD_STEP_FILLED = "SET_THIRD_STEP_FILLED";
const SET_FOURTH_STEP_FILLED = "SET_FOURTH_STEP_FILLED";
const SET_FIFTH_STEP_FILLED = "SET_FIFTH_STEP_FILLED";

export const setFirstStepFilled = firstStepFilled => ({
	type: SET_FIRST_STEP_FILLED, firstStepFilled,
})
export const setSecondStepFilled = secondStepFilled => ({
	type: SET_SECOND_STEP_FILLED, secondStepFilled,
})
export const setThirdStepFilled = thirdStepFilled => ({
	type: SET_THIRD_STEP_FILLED, thirdStepFilled,
})
export const setFourthStepFilled = fourthStepFilled => ({
	type: SET_FOURTH_STEP_FILLED, fourthStepFilled,
})
export const setFifthStepFilled = fifthStepFilled => ({
	type: SET_FIFTH_STEP_FILLED, fifthStepFilled,
})

export default (state = initialState, action) => {
	switch (action.type) {
		case SET_FIRST_STEP_FILLED:
			return {...state, firstStepFilled: action.firstStepFilled}
		case SET_SECOND_STEP_FILLED:
			return {...state, secondStepFilled: action.secondStepFilled}
		case SET_THIRD_STEP_FILLED:
			return {...state, thirdStepFilled: action.thirdStepFilled}
		case SET_FOURTH_STEP_FILLED:
			return {...state, fourthStepFilled: action.fourthStepFilled}
		case SET_FIFTH_STEP_FILLED:
			return {...state, fifthStepFilled: action.fifthStepFilled}
		default:
			return state
	}
};
