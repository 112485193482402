const initialState = {
	loadDataFromLocalstorage: false,
	blockAnyDataLoading: false, // variable set to true after first page change (from page 1 to 2)
}

const TOGGLE_LOAD_DATA_FROM_LOCALSTORAGE = "TOGGLE_LOAD_DATA_FROM_LOCALSTORAGE";
const TOGGLE_BLOCK_LOAD_DATA = "TOGGLE_BLOCK_LOAD_DATA";

export const toggleLoadDataFromLocalstorage = loadDataFromLocalstorage => ({
	type: TOGGLE_LOAD_DATA_FROM_LOCALSTORAGE, loadDataFromLocalstorage,
})

export const toggleBlockAnyDataLoading = blockAnyDataLoading => ({
	type: TOGGLE_BLOCK_LOAD_DATA, blockAnyDataLoading,
})

export default (state = initialState, action) => {
	if (action.type === TOGGLE_LOAD_DATA_FROM_LOCALSTORAGE) {
		return { ...state, loadDataFromLocalstorage: action.loadDataFromLocalstorage }
	} else if (action.type === TOGGLE_BLOCK_LOAD_DATA) {
		return { ...state, blockAnyDataLoading: action.blockAnyDataLoading }
	}
	else {
		return state
	}
};
