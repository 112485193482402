import React from "react"
import { Provider } from "react-redux"
import { createStore as reduxCreateStore, applyMiddleware } from "redux"
import rootReducer from "."
import promise from "redux-promise-middleware"
import logger from "redux-logger"

const createStore = () => reduxCreateStore(rootReducer, applyMiddleware(promise, logger))

export default ({ element }) => (
  <Provider store={createStore()}>{element}</Provider>
);
