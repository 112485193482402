const initialState = {
	//page 1
	propertyType: null,
	propertyTypeLabel: null,
	houseType: null,
	houseTypeLabel: null,
	roofType: null,
	roofTypeLabel: null,
	wallsConstructionType: null,
	wallsConstructionTypeLabel: null,
	buildYear: null,
	buildYearLabel: null,
	beginningDateOfBuilding: null,
	beginningDateOfBuildingLabel: null,
	expectedBuildingFinishDate: null,
	expectedBuildingFinishDateLabel: null,
	progressOfWork: null,
	progressOfWorkLabel: null,
	propertyArea: null,
	lastRenovationDate: null,
	lastRenovationDateLabel: null,
	flatFloor: null,
	flatFloorLabel: null,
	zipCode: null,
	lat: null,
	lng: null,
	city: null,
	street: "słowackiego",
	buildingNumber: null,
	flatNumber: null,
	//page 2
	houseOwnershipType: null,
	houseOwnershipTypeLabel: null,
	flatOwnershipType: null,
	flatOwnershipTypeLabel: null,
	// ownerBirthDate: null,
	// policyholderBirthDate: null,
	// peopleLivePermanently: null,
	// peopleLivePermanentlyLabel: null,
	// notAdultPeopleLivePermanently: null,
	// notAdultPeopleLivePermanentlyLabel: null,
	// notAdultPeopleLivePermanentlyData: [],
	farmSize: null,
	propertyPermanentlyInhabited: null,
	economicActivity: null,
	economicActivityLabel: null,
	areaPartForEconomicActivity: null,
	areaPartForEconomicActivityLabel: null,
	economicActivityType: null,
	economicActivityTypeLabel: null,
	propertyForRent: null,
	propertyForRentLabel: null,
	assignmentToBank: null,
	assignmentToBankLabel: null,

	//page 3
	yearsPropertyWasInsured: null,
	yearsPropertyWasInsuredLabel: null,
	lastInsuranceAgency: null,
	lastInsuranceAgencyLabel: null,
	damageAmountLastYear: null,
	damageAmountLastYearLabel: null,
	damageAmountTwoYearsAgo: null,
	damageAmountTwoYearsAgoLabel: null,
	damageAmountThreeYearsAgo: null,
	damageAmountThreeYearsAgoLabel: null,
	floodDamageAmount: null,
	floodDamageAmountLabel: null,

	//page 4
	insuranceBeginningDate: null,
	wallsAndPermanentElementsInsuranceCovered: false,
	equipmentInsuranceCovered: false,
	constructionMaterialsInsuranceCovered: false,
	farmInsuranceCoverageObligatory: false,
	farmInsuranceCoverageVoluntary: false,
	multiValveLockAmount: null,
	multiValveLockAmountLabel: null,
	certifiedLock: null,

	isRandomEventInsured: null,
	isRandomEventInsuredLabel: null,
	isTheftInsured: null,
	isTheftInsuredLabel: null,
	isFloodInsured: null,
	isFloodInsuredLabel: null,
	isVandalismInsured: null,
	isVandalismInsuredLabel: null,
	isGlassItemInsured: null,
	isGlassItemInsuredLabel: null,

	isIntercom: null,
	isLocalAlarm: null,
	isAlarmWithIntervention: null,
	isConstantSupervision: null,
	isSecurityDoor: null,
	isMonitoring: null,
	isWindowSecurity: null,

	//page 5
	propertyValue: null,
	propertyValueLabel: null,
	permanentItemsInsuranceAmount: null,
	equipmentInsuranceAmount: null,
	emailAddress: null,
	phoneNumber: null,
	prefferedContactTime: null,
	prefferedContactTimeLabel: null,
	isOCInsuranceInPrivateLife: null,
	isOCInsuranceInPrivateLifeLabel: null,
	gettingEmailsAccepted: false,
}

const SET_FORM_VALUE = "SET_FORM_VALUE"

export const setFormValueAction = (name, value) => ({
  type: SET_FORM_VALUE,
  payload: { name, value }
})

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FORM_VALUE:
    	console.log('form ' + JSON.stringify(state))
      return { ...state, [action.payload.name]: action.payload.value }
    default:
      return state
  }
};
