import axios from "axios"

export const getDataFromLead = (leadUuid) => (axios.get(process.env.GET_DATA_FROM_FORT_WEB_API + leadUuid))
export const fetchCities = (cityId) => axios.get(process.env.API_URL + "/cities/" + cityId)
export const sendEmail = (leadUuid, data) => axios.post(process.env.POST_DATA_TO_FORT_WEB_API_URL + leadUuid + '/sendEmail/' , data)
export const sendDataToFortWebApi = (data) => axios.post(process.env.POST_DATA_TO_FORT_WEB_API_URL, data);


// export const sendDataToFortWebApi = (data) => axios({
// 	method: 'post',
// 	url: process.env.POST_DATA_TO_FORT_WEB_API_URL,
// 	data: data,
// 	headers: {
// 		'Accept': 'application/json',
// 		'Content-Type': 'application/json',
// 		'Access-Control-Allow-Origin': '*',
// 		'Access-Control-Allow-Headers': 'origin, x-requested-with, content-type',
// 		'Access-Control-Allow-Methods': 'PUT, GET, POST, DELETE, OPTIONS'
// 	}
// })
