import React, { useMemo } from "react";
import styled from "styled-components"
import Lottie from "react-lottie";

const AnimationWrapper = styled.div`
	margin-left: 0;
	height: 203px;	
	@media(min-width: 1024px) {
		margin-left: 0;
		height: 200px;
		@media(min-height: 768px) {
			height: 270px;
			margin-left: -20px;
		}
		@media(min-height: 900px) {
			margin-left: -30px;
			height: 300px;
		}
		@media(min-height: 1024px) {
			margin-left: -50px;
			height: 280px;
		}
	}
	@media(min-width: 1280px) {
		height: 195px;
		margin-left: -10px;
		@media(min-height: 768px) {
			height: 240px;
			margin-left: -40px;
		}
		@media(min-height: 900px) {
			margin-left: -30px;
			height: 220px;
		}
		@media(min-height: 1024px) {
			margin-left: -50px;
			height: 260px;
		}
	}
	@media(min-width: 1920px) {
		margin-left: 0;
		@media(min-height: 768px) {
			height: 240px;
			margin-left: -10px;
		}
		@media(min-height: 900px) {
			margin-left: 0;
			height: 205px;
		}
		@media(min-height: 950px) {
			margin-left: -10px;
			height: 245px;
		}
		@media(min-height: 1024px) {
			height: 320px;
			margin-left: -50px;
		}
	}
	div {	
		@media(min-width: 1024px) {
			width: calc(100% - 40px) !important;
			@media(min-height: 768px){
				width: calc(100% + 20px) !important;
			}
			@media(min-height: 900px) {
				width: calc(100% + 30px) !important;
			}
			@media(min-height: 1024px) {
				width: calc(100% + 50px) !important;
			}		
		}
		@media(min-width: 1280px) {
			width: calc(100% + 10px) !important;
			@media(min-height: 768px){
				width: calc(100% + 40px) !important;
			}
			@media(min-height: 900px) {
				width: calc(100% + 30px) !important;
			}
			@media(min-height: 1024px) {
				width: calc(100% + 50px) !important;
			}	
		}
		@media(min-width: 1920px) {
			width: calc(100% - 75px) !important;
			@media(min-height: 768px){
				width: calc(100% - 10px) !important;
			}
			@media(min-height: 900px) {
				width: calc(100% - 35px) !important;
			}
			@media(min-height: 950px) {
				width: calc(100% + 10px) !important;
			}
			@media(min-height: 1024px) {
				width: calc(100% + 50px) !important;
			}
		}
	}
`;

const SingleAnimationComponent = ({animationJson}) => {

	const defaultOptions = {
		loop: false,
		autoplay: true,
		animationData: animationJson,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice"
		}
	};

	return useMemo(() => {
		return (
			<AnimationWrapper className="controlled">
				<Lottie
					options={defaultOptions}
					isStopped={false}
					isPaused={false}
				/>
			</AnimationWrapper>
		);
	}, []);
}

export default SingleAnimationComponent;
