import { fetchCities } from "../services/property-service"

const initialState = {
	loading: false,
	error: false,
	fetched: false,
	data: []
}

const FETCH_CITIES = "FETCH_CITIES"

export const fetchCitiesAction = (cityId) => ({
	type: FETCH_CITIES,
	payload: fetchCities(cityId)
})

export default (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CITIES + "_PENDING":
			return { ...initialState, loading: true }
		case FETCH_CITIES + "_REJECTED":
			return { ...initialState, error: true }
		case FETCH_CITIES + "_FULFILLED":
			return { ...initialState, fetched: true, data: action.payload.data }
		default:
			return state
	}
};
