const initialState = {
	time: "około 150 s"
}

const CHANGE_TIME_LEFT = "CHANGE_TIME_LEFT"

export const changeTimeLeft = time => ({
	type: CHANGE_TIME_LEFT, time,
})

export default (state = initialState, action) => {
	if (action.type === CHANGE_TIME_LEFT) {
		return { ...state, time: action.time }
	} else {
		return state
	}
};
