import React, {useEffect} from "react"
import styled, {createGlobalStyle, ThemeProvider} from "styled-components"
import {theme} from "../utils/theme"
import SideStepsPreview from "../components/sideStepsPreview/SideStepsPreview";
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions";
import AlwisLogo from '../images/SideStepsPreview/alwis-logo-bottom.svg';
import {connect, useDispatch} from "react-redux";
import Helmet from "react-helmet";
import LoadDataModal from "../components/loadDataModal/LoadDataModal";
import {toggleModalVisibility} from "../state/showLoadDataModal";
import {useQueryParam} from "gatsby-query-params";

const GlobalStyle = createGlobalStyle`
html {
		overflow-x: hidden;
	}
	body {
		width: 100%;
		padding: 0;
		margin: 0;
		overflow-x: hidden;
		font-family: 'Source Sans Pro', sans-serif;
		background-color: ${({theme}) => theme.colors.formBlue};
		-webkit-tap-highlight-color: transparent;
		@media(min-width: 1024px) {
			background-color: ${({theme}) => theme.colors.formWhite};
		}
	}
	
	button {
		font-family: 'Source Sans Pro', sans-serif;
		&:focus {
			outline: none;
		}
	}
	
	a {
		&:focus {
		outline: none;
		}
	}

	*, *::before, *::after{
		box-sizing: border-box;
	}
	
	*::placeholder {
		font-family:'Source Sans Pro', sans-serif;
	}
	
	h1, h2, h3, h4, h5, h6 {
		margin: 0; 
		padding: 0;
	}
	
	p {
		margin: 0;
		padding: 0;
	}
	
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}

	@font-face {
	font-family: 'OpenSans';
	font-style: normal;
	font-weight: 300;
	src: url('../static/fonts/OpenSans-Light.ttf') format('truetype');
	}
	@font-face {
	font-family: 'OpenSans';
	font-style: normal;
	font-weight: 400;
	src: url('../static/fonts/OpenSans-Regular.ttf') format('truetype');
	}
	@font-face {
	font-family: 'OpenSans';
	font-style: normal;
	font-weight: 600;
	src: url('../static/fonts/OpenSans-SemiBold.ttf') format('truetype');
	}
	@font-face {
	font-family: 'OpenSans';
	font-style: normal;
	font-weight: 700;
	src: url('../static/fonts/OpenSans-Bold.ttf') format('truetype');
	}
	@font-face {
	font-family: 'OpenSans';
	font-style: normal;
	font-weight: 800;
	src: url('../static/fonts/OpenSans-ExtraBold.ttf') format('truetype');
	}

	@font-face {
	font-family: 'SourceSansPro';
	font-style: normal;
	font-weight: 200;
	src: url('../static/fonts/SourceSansPro-Extralight.ttf') format('truetype');
	}
	@font-face {
	font-family: 'SourceSansPro';
	font-style: normal;
	font-weight: 300;
	src: url('../static/fonts/SourceSansPro-Light.ttf') format('truetype');
	}
	@font-face {
	font-family: 'SourceSansPro';
	font-style: normal;
	font-weight: 400;
	src: url('../static/fonts/SourceSansPro-Regular.ttf') format('truetype');
	}
	@font-face {
	font-family: 'SourceSansPro';
	font-style: normal;
	font-weight: 600;
	src: url('../static/fonts/SourceSansPro-SemiBold.ttf') format('truetype');
	}
	@font-face {
	font-family: 'SourceSansPro';
	font-style: normal;
	font-weight: 700;
	src: url('../static/fonts/SourceSansPro-Bold.ttf') format('truetype');
	}
	@font-face {
	font-family: 'SourceSansPro';
	font-style: normal;
	font-weight: 900;
	src: url('../static/fonts/SourceSansPro-ExtraBold.ttf') format('truetype');
	}





`;

const StyledWrapper = styled.div`
	margin-top: 90px;
	border-radius: 40px;
	background-color: white;
	z-index: 101;
    position: ${({isPopupShown, isLoadDataModalShown, path, isLeadInUrl}) => (isPopupShown || (!isLeadInUrl && isLoadDataModalShown && path ==='/')) ? "fixed" : "unset"};
	div.views {
		div.view-container {
			div.view {
				div.tl-edges {
					overflow-x: ${({isPopupShown, isLoadDataModalShown, path, isLeadInUrl}) => (isPopupShown || (!isLeadInUrl && isLoadDataModalShown && path ==='/')) ? "visible" : "hidden"};
				}
			}
		}
	}
	&.tl-edges {
		background-color: blue;
	}
	@media(min-width: 768px) {
		margin-top: 125px;
	}
	@media(min-width: 1024px) {
		margin-top: 0;
		margin-left: 340px;
		border-radius: 0;
	}
	@media(min-width: 1280px) {
		margin-left: 340px;
	}
	@media(min-width: 1920px) {
		margin-left: 405px;
	}
	div {
		div {
			box-shadow: none!important;
		}
	} 
`;

const InfoWrapper = styled.div`
	position: ${({isPopupShown}) => isPopupShown ? "unset" : "fixed"};;
	bottom: 0;
	z-index: 100;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	padding: 8px 20px 10px;
  	align-items: center;
	background-color: ${({theme}) => theme.colors.formBlue};
	@media(min-width: 1024px) {
		display: none;
	}
`;

const InfoText = styled.p`
	font-size: 14px;
	font-weight: ${({theme}) => theme.fontWeight.regular};
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	text-align: left;
  	width: 30%;
	&.align-right {
		text-align: right;
	}
	span {
		font-weight: ${({theme}) => theme.fontWeight.black};
		line-height: 18px;
	}
	span.calculation {
        color: ${({theme}) => theme.colors.formAlwisOrange};
	    line-height: 12px;
	  	font-size: 12px;
      	font-weight: ${({theme}) => theme.fontWeight.regular};
	  }
`;

const BottomLogoWrapper = styled.a`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%; 
`;

const LogoImage = styled.img`
	height: 35px;
`

const Layout = (props) => {
	const {children, path, location, timeLeft, isPopupShown, isLoadDataModalShown, lead} = props;
	const dispatch = useDispatch()
	const isPropertyFormInLocalStorage = (typeof window !== "undefined") ? ("propertyForm" in localStorage && localStorage.getItem("propertyForm") !== "" && !(localStorage.getItem("propertyForm") === "null")) : false;
	let leadUuid = useQueryParam('lead', '');
	const isLeadInUrl = leadUuid !== null;
	useEffect(() => {
		if (!isLeadInUrl && isPropertyFormInLocalStorage) {
			dispatch(toggleModalVisibility(true))
		} else {
			dispatch(toggleModalVisibility(false))
		}
	}, []);
	const isHomePage = (path === '/');
	return (
		<ThemeProvider theme={theme}>
			<Helmet>
				<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap" rel="stylesheet" />
				<link href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap" rel="stylesheet" />
			</Helmet>
			{/*<Helmet>*/}
			{/*	<script type="text/javascript">*/}
			{/*		{`(function () {var script = document.createElement('script');script.src = 'https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js';script.async = false;document.head.appendChild(script);}())</script><script>var __cp = {"id":(function(){ if (location.pathname == 'https:\\/\\/www.polisonet.pl\\/kalkulator\\/') {return "2lArJZa2P7dp7kmRsLzxp9TDsK4RR3QDPGFqXfYc5qA";} else {return "lVKRoUggNJ16ZhXn8L4Avo4wk3g0MglVZZfz3x6aOKc";}}()),"version":"1.1"};(function (window, document) {var cp = document.createElement('script');cp.type = 'text/javascript';cp.async = false;cp.src = "++cdn-widget.callpage.io+build+js+callpage.js".replace(/[+]/g, '/').replace(/[=]/g, '.');var s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(cp, s);if (window.callpage) {alert('You could have only 1 CallPage code on your website!');} else {window.callpage = function (method) {if (method == '__getQueue') {return this.methods;}else if (method) {if (typeof window.callpage.execute === 'function') {return window.callpage.execute.apply(this, arguments);}else {(this.methods = this.methods || []).push({arguments: arguments});}}};window.callpage.__cp = __cp;window.callpage('api.button.autoshow');}})(window, document);`}*/}
			{/*	</script>*/}
			{/*</Helmet>*/}
			<>
				<GlobalStyle/>
				<SideStepsPreview path={path}/>
				{!isLeadInUrl && isLoadDataModalShown && isHomePage && <LoadDataModal/>}
				<StyledWrapper isLeadInUrl={isLeadInUrl} isPopupShown={isPopupShown} isLoadDataModalShown={isLoadDataModalShown} path={path}>
					<TransitionProvider location={location}
					                    mode="successive"
					                    enter={{
						                    opacity: 0,
						                    transform: "translate3d(60vw,0,0)",
						                    config: {
							                    mass: 1.5,
							                    tension: 100,
							                    friction: 20,
							                    clamp: false
						                    },
						                    onRest: () => {
						                    }
					                    }}
					                    usual={{
						                    opacity: 1,
						                    transform: "translate3d(0vh,0vh,0)"
					                    }}
					                    leave={{
						                    opacity: 0,
						                    transform: "translate3d(-5vw,0vh,0)",
						                    config: {
							                    duration: 100
						                    }
					                    }}
					                    config={{
					                    	duration: 0
					                    }}
					                    >
						<TransitionViews>
						{children}
						</TransitionViews>
					</TransitionProvider>
				</StyledWrapper>
				<InfoWrapper isPopupShown={isPopupShown}>
					<InfoText>kalkulacja:<br/><span className="calculation">{lead}</span></InfoText>
					<BottomLogoWrapper target="_blank" href={"https://alwis.pl/"}>
						<LogoImage src={AlwisLogo}/>
					</BottomLogoWrapper>
					<InfoText className="align-right">pozostało:<br/><span>{timeLeft}</span></InfoText>
				</InfoWrapper>
			</>
		</ThemeProvider>
	)
};

export default connect(state => ({
	timeLeft: state.timeLeft.time,
	lead: state.lead.lead,
	isPopupShown: state.showPopup.isPopupShown,
	isLoadDataModalShown: state.showLoadDataModal.isLoadDataModalShown,
	isNotSellingModalShown: state.showNotSellingModal.isNotSellingModalShown,
}), null)(Layout)
