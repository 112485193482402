const initialState = {
	lead: ""
}

const CHANGE_LEAD = "CHANGE_LEAD"

export const changeLead = lead => ({
	type: CHANGE_LEAD, lead,
})

export default (state = initialState, action) => {
	if (action.type === CHANGE_LEAD) {
		return { ...state, lead: action.lead }
	} else {
		return state
	}
};
