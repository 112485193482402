const initialState = {
	isPopupShown: false,
}

const TOGGLE_POPUP_VISIBILITY = "TOGGLE_POPUP_VISIBILITY";

export const togglePopupVisibility = isPopupShown => ({
	type: TOGGLE_POPUP_VISIBILITY, isPopupShown,
})


export default (state = initialState, action) => {
	if (action.type === TOGGLE_POPUP_VISIBILITY) {
		return { ...state, isPopupShown: action.isPopupShown }
	} else {
		return state
	}
};
